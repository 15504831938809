import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguageData } from '../../Context/LanguageContext';
import { selectKenoType, setKenoType } from '../../reduxToolkit/slices/kenoType.slices';

const DurationButton = ({ duration }) => {
  const dispatch = useDispatch();
  const languageData = useLanguageData();
  const kenoType = useSelector(selectKenoType);

  return (
    <label className="game-duration__label">
      <input
        className="game-duration__input"
        type="radio"
        name="game-duration"
        defaultChecked={duration === kenoType}
      />
      <span onClick={() => dispatch(setKenoType(duration))} className="game-duration__text">
        {duration} {languageData.minutes}.
      </span>
    </label>
  );
};

export default DurationButton;
