import React, { createContext, useContext, useEffect } from 'react';
import useSound from 'use-sound';
import sounds from '../assets/sounds/index';
import { useDispatch } from 'react-redux';
import { setSoundAvtive } from '../reduxToolkit/slices/game.slices';

const SoundContext = createContext();

const SoundProvider = ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    const storageSound = JSON.parse(localStorage.getItem('sound'));
    const initialSound = storageSound ?? true;

    dispatch(setSoundAvtive(initialSound));
  }, []);

  const { ballDrop, addReduceAmount, select, win, bet, stars } = sounds;

  const [ballDropSound] = useSound(ballDrop);
  const [addReduceAmountSound] = useSound(addReduceAmount);
  const [selectSound] = useSound(select);
  const [winSound] = useSound(win);
  const [betSound] = useSound(bet);
  const [starsSound] = useSound(stars);

  const playSound = {
    ballDrop: () => ballDropSound(),
    addReduceAmount: () => addReduceAmountSound(),
    select: () => selectSound(),
    win: () => winSound(),
    bet: () => betSound(),
    stars: () => starsSound(),
  };

  return <SoundContext.Provider value={playSound}>{children}</SoundContext.Provider>;
};

export const useSoundConstext = () => {
  return useContext(SoundContext);
};

export default SoundProvider;
