import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isFetching: false,
};

export const fetchSlices = createSlice({
  name: 'fetch',
  initialState,
  reducers: {
    setIsFetching: (state) => {
      state.isFetching = true;
    },
    resetIsFetching: (state) => {
      state.isFetching = false;
    },
  },
});

// ACTIONS
export const { setIsFetching, resetIsFetching } = fetchSlices.actions;

// SELECTORS
export const selectIsFetching = (state) => state.fetch.isFetching;

export default fetchSlices.reducer;
