import React, { useState } from 'react';
import { forMap } from '../../helpers/general';
import { getTicketBallColor } from '../../helpers/game';
import { useSelector } from 'react-redux';
import { selectBallFrequency } from '../../reduxToolkit/slices/gameData.slices';
import BetButtons from './BetButtons/BetButtons';

const BET_BTNS_COUNT = 80;

const BetButtonsGrid = () => {
  const [hoverBtns, setHoverBtns] = useState([]);
  const ballFrequency = useSelector(selectBallFrequency);

  return (
    <ul className="bet-numbers">
      {forMap(BET_BTNS_COUNT, (index) => {
        const digit = index + 1;
        const color = getTicketBallColor(ballFrequency, digit);
        return (
          <BetButtons
            digit={digit}
            key={index}
            color={color}
            setHoverBtns={setHoverBtns}
            hoverBtns={hoverBtns.includes(digit) ? hoverBtns : ''}
          />
        );
      })}
    </ul>
  );
};

export default BetButtonsGrid;
