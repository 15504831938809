import React, { useEffect } from 'react';
import { useLanguageData } from '../../../../../Context/LanguageContext';
import { useSelector } from 'react-redux';
import { useSoundConstext } from '../../../../../Context/SoundContext';
import { selectIsSoundActive } from '../../../../../reduxToolkit/slices/game.slices';

const TicketDigit = ({ digit, color, isDigitWon }) => {
  const languageData = useLanguageData();
  const useSoundData = useSoundConstext();
  const isSoundActive = useSelector(selectIsSoundActive);

  useEffect(() => {
    if (isDigitWon === true) {
      isSoundActive && useSoundData.stars();
    }
  }, [isDigitWon]);

  return (
    <li className={`current-ticket__number ${isDigitWon ? 'animate-win' : ''}`}>
      {digit ? (
        <>
          <div
            className={`current-ticket__number-text ${color} ${
              isDigitWon ? languageData.win.toLowerCase() : ''
            }`}>
            <div className="current-ticket__number-text-wrapper">{digit}</div>
          </div>
          <span className="current-ticket__number-star" />
          <span className="current-ticket__number-star" />
          <span className="current-ticket__number-star" />
          <span className="current-ticket__number-star" />
        </>
      ) : (
        ''
      )}
    </li>
  );
};

export default TicketDigit;
