import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  confirmedTickets: [],
};

export const sendTicketSlice = createSlice({
  name: 'sendTicket',
  initialState,
  // extraReducers: userDataExtraReducer,
  reducers: {
    addTicket: (state, { payload }) => {
      state.confirmedTickets = [...state.confirmedTickets, payload];
    },
    replaceTickets: (state, { payload }) => {
      state.confirmedTickets = payload;
    },
  },
});

// ACTIONS

export const { addTicket, replaceTickets } = sendTicketSlice.actions;

// SELECTORS
export const selectConfirmedTickets = (state) => state.sendTicket.confirmedTickets;

export default sendTicketSlice.reducer;
