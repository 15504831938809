import { createSlice } from '@reduxjs/toolkit';
import { KENO_TYPES } from '../../constants/game';

const { KENO_1 } = KENO_TYPES;

const initialState = {
  kenoType: KENO_1,
};

export const kenoTypeSlices = createSlice({
  name: 'kenoType',
  initialState,
  reducers: {
    setKenoType: (state, { payload }) => {
      state.kenoType = payload;
    },
  },
});

// ACTIONS
export const { setKenoType } = kenoTypeSlices.actions;

// SELECTORS
export const selectKenoType = (state) => state.kenoType.kenoType;

export default kenoTypeSlices.reducer;
