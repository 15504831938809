import React, { useEffect } from 'react';
import './App.css';
import MenuScene from './MenuScene/Menuscene';
import Header from './Header/Header';
import GameScene from './GameScene/GameScene';
import { useDispatch, useSelector } from 'react-redux';
import { SCENE_NAMES } from '../constants/game';
import LoadingScene from './LoadingScene/LoadingScene';
import ErrorsPopup from './ErrorsPopup/ErrorsPopup';
import { selectActiveScene } from '../reduxToolkit/slices/gameScene.slices';
import Unavailability from './Unavailability/Unavailability';
import { selectIsUnavailable } from '../reduxToolkit/slices/error.slices';
import { getGameDataThunk } from '../reduxToolkit/thunks/gameData.thunk';
import { getUserDataThunk } from '../reduxToolkit/thunks/userData.thunk';
import { selectKenoType } from '../reduxToolkit/slices/kenoType.slices';
import PowerdBy from './PowerdBy/PowerdBy';

const { GAME_SCENE, LOADING_SCENE, MENU_SCENE } = SCENE_NAMES;

function App() {
  const dispatch = useDispatch();
  const activeScene = useSelector(selectActiveScene);
  const isUnavailable = useSelector(selectIsUnavailable);
  const kenoType = useSelector(selectKenoType);

  useEffect(() => {
    dispatch(getGameDataThunk(kenoType));
    dispatch(getUserDataThunk(kenoType));
  }, []);

  return (
    <div className="wrapper wrapper--welcome">
      {activeScene === LOADING_SCENE ? (
        <LoadingScene />
      ) : (
        <>
          {activeScene === GAME_SCENE && <Header />}
          {activeScene === GAME_SCENE ? <GameScene /> : <MenuScene />}
          {activeScene === MENU_SCENE && <PowerdBy />}
        </>
      )}
      <ErrorsPopup />
      {isUnavailable && <Unavailability />}
    </div>
  );
}

export default App;
