import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { resetIsFetching, setIsFetching } from '../slices/fetch.slices';
import { getToken, url } from '../../constants/api';
import { setIsUnavailable } from '../slices/error.slices';
import { setUserBalance } from '../slices/userData.slices';

export const getUserDataThunk = createAsyncThunk(
  'userData/getUserDataThunk',
  async (_, { dispatch }) => {
    try {
      dispatch(setIsFetching());

      const response = await axios.get(`${url}/get-balance`, {
        params: {
          token: getToken(),
        },
      });

      const userBalance = response.data.data;
      dispatch(setUserBalance(userBalance.balance));
    } catch (error) {
      dispatch(setIsUnavailable(true));
    } finally {
      dispatch(resetIsFetching());
    }
  },
);
