import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedBtns: [],
};

export const betBtnSlices = createSlice({
  name: 'betBtn',
  initialState,
  reducers: {
    addBetButton: (state, { payload }) => {
      state.selectedBtns = [...state.selectedBtns, payload];
    },
    addGroupBetButton: (state, { payload }) => {
      state.selectedBtns = [...state.selectedBtns, ...payload];
    },
    addRandomBetBtns: (state, { payload }) => {
      state.selectedBtns = payload;
    },
    deleteGroupBetButton: (state, { payload }) => {
      state.selectedBtns = payload;
    },
    deleteBetButton: (state, { payload }) => {
      state.selectedBtns = state.selectedBtns.filter((digit) => {
        return digit !== payload;
      });
    },
    resetBetButtons: (state) => {
      state.selectedBtns = [];
    },
  },
});

// ACTIONS
export const {
  addBetButton,
  addGroupBetButton,
  addRandomBetBtns,
  deleteGroupBetButton,
  deleteBetButton,
  resetBetButtons,
} = betBtnSlices.actions;

// SELECTORS
export const selectBetBtns = (state) => state.betBtn.selectedBtns;

export default betBtnSlices.reducer;
