// FOR PRODUCTION
export const url = '/api/v1'; //USE THIS IN PRODUCTION
export const getToken = () => localStorage.getItem('token');
export const getLanguage = () => localStorage.getItem('fallbackLanguage');

// FOR DEVELOPMENT
// export const url = 'http://oliver.energaming.systems:20035/api/v1'; // DEVELOPMENT DB
// export const getToken = () => 'test-frontend-token'
// export const url = 'https://keno.games.staging.energaming.services/api/v1';

// export const url = 'https://keno.nrgaming.games/api/v1';
