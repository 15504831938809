import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BALL_COUNTS,
  digitsWithoutBottomBtn,
  digitsWithoutGroupBtn,
  digitsWithoutRightBtn,
} from '../../../constants/game';
import { useSoundConstext } from '../../../Context/SoundContext';
import {
  addBetButton,
  addGroupBetButton,
  deleteBetButton,
  deleteGroupBetButton,
  selectBetBtns,
} from '../../../reduxToolkit/slices/betBtn.slices';
import { setError } from '../../../reduxToolkit/slices/error.slices';
import {
  selectIsBetCanceled,
  selectIsSoundActive,
  setIsBetCanceled,
} from '../../../reduxToolkit/slices/game.slices';

const BetButtons = memo(({ digit, color, setHoverBtns, hoverBtns }) => {
  const dispatch = useDispatch();
  const selectedBetBtns = useSelector(selectBetBtns);
  const isBetCanceled = useSelector(selectIsBetCanceled);
  const isSoundActive = useSelector(selectIsSoundActive);
  const useSoundData = useSoundConstext();

  const isActive = selectedBetBtns.includes(digit);
  const groupDigits = [digit, digit + 1, digit + 10, digit + 11];
  const rightLeftBtns = [digit, digit + 1];
  const upDownBtns = [digit, digit + 10];

  const handleClick = (digit) => {
    isSoundActive && useSoundData.select();
    isBetCanceled && dispatch(setIsBetCanceled(false));

    if (isActive) {
      dispatch(deleteBetButton(digit));
    } else {
      selectedBetBtns.length < BALL_COUNTS.USER_TICKET && dispatch(addBetButton(digit));
      if (selectedBetBtns.length === BALL_COUNTS.USER_TICKET) {
        dispatch(setError('The ticket cannot contain more than 10 numbers.'));
      }
    }
  };

  const handleGroupClick = (props) => {
    isSoundActive && useSoundData.select();
    isBetCanceled && dispatch(setIsBetCanceled(false));
    const intersection = props.filter((element) => !selectedBetBtns.includes(element));
    const intLen = intersection.length;
    const remainingBalls = selectedBetBtns.filter((element) => !props.includes(element));
    if (intLen > 0 && selectedBetBtns.length + intLen <= 10) {
      dispatch(addGroupBetButton(intersection));
    } else {
      intLen === 0 && dispatch(deleteGroupBetButton(remainingBalls));
      if (selectedBetBtns.length + intLen > 10) {
        dispatch(setError('The ticket cannot contain more than 10 numbers.'));
      }
    }
  };

  return (
    <div className="bet-number-container">
      <button
        className={`bet-number-button ${color} ${isActive ? 'active' : ''} ${
          hoverBtns.includes(digit) ? 'group-btns_hover' : ''
        }`}>
        <span className="bet-number-button__span" onClick={() => handleClick(digit)}>
          {digit}
        </span>
      </button>
      {!(digit in digitsWithoutGroupBtn) && (
        <button
          className="bet-number-select-group"
          onMouseOver={() => setHoverBtns(groupDigits)}
          onMouseOut={() => setHoverBtns([])}
          onClick={() => handleGroupClick(groupDigits)}
        />
      )}
      {!(digit in digitsWithoutRightBtn) && (
        <button
          onMouseOver={() => setHoverBtns(rightLeftBtns)}
          onMouseOut={() => setHoverBtns([])}
          onClick={() => handleGroupClick(rightLeftBtns)}
          className="right-buttons"></button>
      )}
      {!(digit in digitsWithoutBottomBtn) && (
        <button
          onMouseOver={() => setHoverBtns(upDownBtns)}
          onMouseOut={() => setHoverBtns([])}
          onClick={() => handleGroupClick(upDownBtns)}
          className="bottom-buttons"></button>
      )}
    </div>
  );
});

export default memo(BetButtons);
