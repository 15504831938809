import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguageData } from '../../Context/LanguageContext';
import {
  closeErrorsPopup,
  selectErrorsPopup,
  selectMaxBetWarning,
  selectMinBetWarning,
} from '../../reduxToolkit/slices/error.slices';
import { selectBetAmountOption } from '../../reduxToolkit/slices/gameVersions.slices';

const ErrorsPopup = () => {
  const dispatch = useDispatch();
  const [errorMessage, serErrorMessage] = useState();
  const languageData = useLanguageData();
  const errorsPopup = useSelector(selectErrorsPopup);
  const isMinBetWarning = useSelector(selectMinBetWarning);
  const isMaxBetWarning = useSelector(selectMaxBetWarning);
  const betAmountOption = useSelector(selectBetAmountOption);

  useEffect(() => {
    if (isMinBetWarning) {
      serErrorMessage(
        `${languageData['Minimum bet is']} ${betAmountOption.minBet}. ${languageData['Please, enter an amount within the allowed limit.']}`,
      );
    } else if (isMaxBetWarning) {
      serErrorMessage(
        `${languageData['Maximum bet is']} ${betAmountOption.maxBet}. ${languageData['Please, enter an amount within the allowed limit.']}`,
      );
    }
  }, [errorsPopup, isMinBetWarning, isMaxBetWarning]);

  return (
    <div className={`${errorsPopup ? 'errorPopup active' : 'errorPopup'}`}>
      <div className="errorPopup__container">
        <div style={{ maxHeight: '10px', maxWidth: '10px' }}>
          <button onClick={() => dispatch(closeErrorsPopup())} className="popup__close">
            Close popup
          </button>
        </div>
        <div className="errorPopup__title">
          {isMinBetWarning || isMaxBetWarning ? errorMessage : languageData[errorsPopup]}
        </div>
      </div>
      <div className="popup__wrapper" />
    </div>
  );
};

export default ErrorsPopup;
