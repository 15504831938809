import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setIsBetCanceled } from '../slices/game.slices';
import { resetBetButtons } from '../slices/betBtn.slices';
import { updateUserBalance } from '../slices/userData.slices';
import { addTicket } from '../slices/sendTicket.slices';
import { setError } from '../slices/error.slices';
import { getToken, url } from '../../constants/api';
import { selectDrawInfo } from '../slices/gameData.slices';
import { selectAmount } from '../slices/amount.slices';
import { selectKenoType } from '../slices/kenoType.slices';

export const sendTicketThunk = createAsyncThunk(
  'ticket/sendTicketThunk',
  async (ticket, { dispatch, getState }) => {
    try {
      const raceId = selectDrawInfo(getState());
      const amount = selectAmount(getState());
      const kenoType = selectKenoType(getState());

      const response = await axios.post(
        `${url}/place-bet`,
        {
          token: getToken(),
          balls: ticket.digits,
          amount: String(amount),
          duration: kenoType,
          raceId: raceId?.drawId,
        },
        {
          timeout: 10 * 1000,
        },
      );

      const { data, error } = response.data;

      if (error) {
        dispatch(setIsBetCanceled(true));
        dispatch(
          setError(
            'Sorry, an unexpected error occurred while placing your bet. Please, try again later or contact support for assistance.',
          ),
        );
      } else {
        dispatch(addTicket(ticket));
        dispatch(resetBetButtons());
        dispatch(updateUserBalance(data.balance));
      }
    } catch (error) {
      console.log('sendTicket error: ', error);
      dispatch(setIsBetCanceled(true));
      if (`${error}` === 'Error: Network Error') {
        dispatch(setError('Error. Low internet connection.'));
      } else {
        dispatch(setError('Something went wrong, please try again.'));
      }
    }
  },
);
