import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GameContainerLeft from '../GameContainerLeft/GameContainerLeft';
import GameContainerRight from '../GameContainerRight/GameContainerRight';
import { setError } from '../../reduxToolkit/slices/error.slices';
import { setFrontSecTillDraw } from '../../reduxToolkit/slices/frontSecTillDraw.slices';
import { selectKenoType } from '../../reduxToolkit/slices/kenoType.slices';
import { showRaffle } from '../../reduxToolkit/slices/raffle.slices';
import { selectSecTillDraw } from '../../reduxToolkit/slices/secondTillDraw.slices';
import { getDrawResultsThunk } from '../../reduxToolkit/thunks/drawResult.thunk';

const GameScene = () => {
  const dispatch = useDispatch();
  const kenoType = useSelector(selectKenoType);
  const secTillDraw = useSelector(selectSecTillDraw);

  useEffect(() => {
    const timerInterval = requestTimer(secTillDraw);

    return () => clearInterval(timerInterval);
  }, [secTillDraw]);

  return (
    <main className="game-grid">
      <div className="container">
        <GameContainerLeft />
        <GameContainerRight />
      </div>
    </main>
  );

  function requestTimer(sec) {
    let timerInSeconds = sec;
    const _timerCountDown = () => {
      dispatch(setFrontSecTillDraw(timerInSeconds));
      timerInSeconds--;
      if (timerInSeconds === 1) {
        dispatch(getDrawResultsThunk(kenoType)).then(() => {
          dispatch(showRaffle());
        });
      }
      if (timerInSeconds < 0) {
        dispatch(setError('Error. Low internet connection.'));
      }
    };
    return setInterval(() => _timerCountDown(), 1000);
  }
};

export default GameScene;
