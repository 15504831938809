import React from 'react';
import { useSelector } from 'react-redux';
import { useLanguageData } from '../../../../../Context/LanguageContext';
import { selectAmount } from '../../../../../reduxToolkit/slices/amount.slices';
import { selectCurrency } from '../../../../../reduxToolkit/slices/gameVersions.slices';
import { selectConfirmedTickets } from '../../../../../reduxToolkit/slices/sendTicket.slices';
import { selectLastPayout } from '../../../../../reduxToolkit/slices/game.slices';

const TicketHeader = () => {
  const selectedAmount = useSelector(selectAmount);
  const currency = useSelector(selectCurrency);
  const ticketReducer = useSelector(selectConfirmedTickets);
  const lastPayout = useSelector(selectLastPayout);

  const languageData = useLanguageData();

  const number = ticketReducer.length + 1;
  const payout = !!lastPayout ? 'x' + lastPayout : '';

  return (
    <div className="current-ticket__grid current-ticket__grid--header">
      <div className="current-ticket__row">
        <div className="current-ticket__ticket-number">
          {languageData.ticket} {number}
        </div>
      </div>
      <div className="current-ticket__row">
        <div className="current-ticket__bet-title">
          {languageData.bet} {selectedAmount} {currency}
        </div>
        <div className="current-ticket__rate-odds">{payout}</div>
      </div>
    </div>
  );
};

export default TicketHeader;
