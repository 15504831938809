import React from 'react';
import { forMap } from '../../../helpers/general';
import RandomDigit from './RandomDigit';
import { useDispatch, useSelector } from 'react-redux';
import { generateRandomDigits } from '../../../helpers/game';
import { useSoundConstext } from '../../../Context/SoundContext';
import { addRandomBetBtns, selectBetBtns } from '../../../reduxToolkit/slices/betBtn.slices';
import {
  selectIsBetCanceled,
  selectIsSoundActive,
  setIsBetCanceled,
} from '../../../reduxToolkit/slices/game.slices';

const RAND_NUM_COUNT = 10;

const RandomChoice = () => {
  const useSoundData = useSoundConstext();
  const dispatch = useDispatch();
  const selectedBetBtns = useSelector(selectBetBtns);
  const isBetCanceled = useSelector(selectIsBetCanceled);
  const isSoundActive = useSelector(selectIsSoundActive);

  const handleRandomChoice = () => {
    isSoundActive && useSoundData.select();
    isBetCanceled && dispatch(setIsBetCanceled(false));
    const btnCount = selectedBetBtns.length;
    dispatch(addRandomBetBtns(generateRandomDigits(btnCount)));
  };

  return (
    <ul className="random-buttons">
      <li className="random-button-container">
        <button
          className="random-button random-button--with-icon"
          onClick={handleRandomChoice}></button>
      </li>
      {forMap(RAND_NUM_COUNT, (index) => (
        <RandomDigit digit={index + 1} key={index} />
      ))}
    </ul>
  );
};

export default RandomChoice;
