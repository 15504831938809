import React from 'react';
import { useSelector } from 'react-redux';
import { useLanguageData } from '../../../../../Context/LanguageContext';
import { selectAmount } from '../../../../../reduxToolkit/slices/amount.slices';
import { selectLastPayout } from '../../../../../reduxToolkit/slices/game.slices';
import {
  selectBetAmountOption,
  selectCurrency,
} from '../../../../../reduxToolkit/slices/gameVersions.slices';

const PossibleWin = () => {
  const lastPayout = useSelector(selectLastPayout);
  const selectedAmount = useSelector(selectAmount);
  const betAmountOption = useSelector(selectBetAmountOption);
  const currency = useSelector(selectCurrency);

  const languageData = useLanguageData();

  const possibleWin = lastPayout * selectedAmount;
  const { maxWin } = betAmountOption;
  return (
    <div className="current-ticket__grid-possible-winning">
      {languageData.possibleWin}
      <span>
        {possibleWin > maxWin ? maxWin : possibleWin} {currency}
      </span>
    </div>
  );
};

export default PossibleWin;
