import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  count: 0,
};

export const historyCountSlices = createSlice({
  name: 'hitoryCount',
  initialState,
  reducers: {
    countForGettingHistory: (state) => {
      state.count = state.count += 1;
    },
  },
});

// ACTIONS
export const { countForGettingHistory } = historyCountSlices.actions;

// SELECTORS
export const selectHistoryCount = (state) => state.tabs.activeTab;

export default historyCountSlices.reducer;
