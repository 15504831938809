import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BALL_COUNTS } from '../../../../constants/game';
import { useSoundConstext } from '../../../../Context/SoundContext';
import {
  addBetButton,
  deleteBetButton,
  selectBetBtns,
} from '../../../../reduxToolkit/slices/betBtn.slices';
import { setError } from '../../../../reduxToolkit/slices/error.slices';
import {
  selectIsBetCanceled,
  selectIsSoundActive,
  setIsBetCanceled,
} from '../../../../reduxToolkit/slices/game.slices';

const StatisticsNumberNew = ({ number, frequency, temperature, maxHotCold }) => {
  const digit = +number;

  const dispatch = useDispatch();
  const isBetCanceled = useSelector(selectIsBetCanceled);
  const isSoundActive = useSelector(selectIsSoundActive);
  const selectedBetBtns = useSelector(selectBetBtns);
  const useSoundData = useSoundConstext();

  const isActive = selectedBetBtns.includes(digit);

  const blueClass = 'statistics__rectangle-progress statistics__rectangle-progress--blue';
  const redClass = 'statistics__rectangle-progress statistics__rectangle-progress--red';

  const percent =
    temperature === 'hotBalls'
      ? (frequency / maxHotCold) * 100
      : (maxHotCold === 0 ? 1 : maxHotCold / frequency) * 100;

  const handleClick = (digit) => {
    isSoundActive && useSoundData.select();
    isBetCanceled && dispatch(setIsBetCanceled(false));

    if (isActive) {
      dispatch(deleteBetButton(digit));
    } else {
      selectedBetBtns.length < BALL_COUNTS.USER_TICKET && dispatch(addBetButton(digit));
      if (selectedBetBtns.length === BALL_COUNTS.USER_TICKET) {
        dispatch(setError('The ticket cannot contain more than 10 numbers.'));
      }
    }
  };

  return (
    <li className="statistics__item">
      <div onClick={() => handleClick(digit)} className="statistics__number-circle">
        {number}
      </div>
      <div className={temperature === 'hotBalls' ? redClass : blueClass}>
        <div
          className="statistics__rectangle-progress-container"
          style={{ width: `${percent}%` }}
        />
      </div>
    </li>
  );
};

export default StatisticsNumberNew;
