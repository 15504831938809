import scene from './slices/gameScene.slices';
import tabs from './slices/tab.slices';
import amount from './slices/amount.slices';
import betBtn from './slices/betBtn.slices';
import congrats from './slices/congrats.slices';
import error from './slices/error.slices';
import frontSecTillDraw from './slices/frontSecTillDraw.slices';
import fetch from './slices/fetch.slices';
import kenoType from './slices/kenoType.slices';
import game from './slices/game.slices';
import raffle from './slices/raffle.slices';
import gameVersions from './slices/gameVersions.slices';
import secTillDraw from './slices/secondTillDraw.slices';
import gameData from './slices/gameData.slices';
import userData from './slices/userData.slices';
import drawResult from './slices/drawResult.slices';
import sendTicket from './slices/sendTicket.slices';

export const reducers = {
  scene,
  tabs,
  amount,
  betBtn,
  congrats,
  error,
  frontSecTillDraw,
  fetch,
  kenoType,
  game,
  raffle,
  gameVersions,
  secTillDraw,
  gameData,
  userData,
  drawResult,
  sendTicket,
};
