import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drawHistory: [],
};

export const drawResultSlice = createSlice({
  name: 'drawResult',
  initialState,
  reducers: {
    setDrawHistory: (state, { payload }) => {
      state.drawHistory = payload;
    },
  },
});

// ACTIONS

export const { setDrawHistory } = drawResultSlice.actions;

// SELECTORS
export const selectDrawResult = (state) => state.drawResult.drawHistory;

export default drawResultSlice.reducer;
