import React, { useEffect } from 'react';
import images from '../../assets/images';
import { useDispatch } from 'react-redux';
import { getGameVersionsThunk } from '../../reduxToolkit/thunks/gameVersions.thunk';

const LoadingScene = () => {
  const dispatch = useDispatch();
  const storeCredentials = () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const { token } = Object.fromEntries(urlSearchParams.entries());

    localStorage.setItem('token', token);
  };

  useEffect(() => {
    storeCredentials();
    dispatch(getGameVersionsThunk());
  }, []);

  return (
    <main className="loadingScene game-grid--welcome " style={{ flexDirection: 'column' }}>
      <div className="loadingScene__container">
        <div className="loadingScene__logo">
          <div className="loadingScene__text">Powered by</div>
          <img src={images.NrLogo} alt="wrapper keno" className="loadingScene__nr-img" />
        </div>
      </div>
      <div className="loadingScene__loader" style={{ width: '184px', flexGrow: 0 }}>
        <div className="loadingScene__loader-background">
          <div
            className="loadingScene__loader-percent"
            style={{ animation: `loader 1s linear forwards`, width: 0 + '%' }}
          />
        </div>
      </div>
    </main>
  );
};

export default LoadingScene;
