import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import LoaderTimer from '../LoaderTimer';
import { useSelector } from 'react-redux';
import { SCENE_NAMES } from '../../../constants/game';
import { getBeforeAnimationTime, getCorrectTimeTillDraw } from '../../../helpers/general';
import { selectActiveScene } from '../../../reduxToolkit/slices/gameScene.slices';
import { selectIsFetching } from '../../../reduxToolkit/slices/fetch.slices';
import { selectKenoType } from '../../../reduxToolkit/slices/kenoType.slices';
import { selectIsRaffleActive } from '../../../reduxToolkit/slices/raffle.slices';
import { getSecondTillDrawThunk } from '../../../reduxToolkit/thunks/secondTillDraw.thunk';

const { GAME_SCENE } = SCENE_NAMES;

const Loader = () => {
  const dispatch = useDispatch();
  const refLoading = useRef(null);
  const refDuration = useRef(null);
  const activeScene = useSelector(selectActiveScene);
  const isFetching = useSelector(selectIsFetching);
  const kenoType = useSelector(selectKenoType);
  const isRaffleActive = useSelector(selectIsRaffleActive);
  const currentSecond = kenoType === 1 ? 59 : 150;

  useEffect(() => {
    if (activeScene === GAME_SCENE) {
      (async () => {
        const atTheEndOfAnimation = {};
        atTheEndOfAnimation.promise = new Promise((resolve, reject) => {
          atTheEndOfAnimation.resolve = resolve;
        });
        const now = Date.now();
        const secondTillDraw = await dispatch(getSecondTillDrawThunk(kenoType));
        const initialSeconds = secondTillDraw?.payload;
        const inheritanceWidth = ((currentSecond - initialSeconds) / currentSecond) * 100;
        const animation = () => {
          const startAnimation = Date.now();
          const timer = startAnimation - now;
          const currentWidth =
            inheritanceWidth + (timer / 10) * (kenoType === 1 ? 0.01694915 : 0.006666667);
          const currentTime = initialSeconds - +(timer / 1000).toFixed();
          if (refLoading.current) {
            refLoading.current.style.width = currentWidth + '%';
            refDuration.current.innerHTML = getCorrectTimeTillDraw(currentTime);
          }
          if (currentTime > currentSecond) {
            refDuration.current.innerHTML = getBeforeAnimationTime(kenoType);
          }
          if (currentTime < 0) {
            atTheEndOfAnimation.resolve();
          }
          if (currentTime >= 0) {
            requestAnimationFrame(animation);
          }
        };
        requestAnimationFrame(animation);
        atTheEndOfAnimation.promise.then(() => {
          if (refLoading.current && refDuration.current) {
            refLoading.current.style.width = 0;
            refDuration.current.innerHTML = getBeforeAnimationTime(kenoType);
          }
        });
      })();
    }
  }, [activeScene, isRaffleActive]);

  return (
    <>
      <div className="loader">
        <div className="loader__background">
          {!isFetching && <div ref={refLoading} className="loader__percent" />}
        </div>
      </div>
      {activeScene === GAME_SCENE && <LoaderTimer refDuration={refDuration} />}
    </>
  );
};

export default Loader;
