import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguageData } from '../../../Context/LanguageContext';
import { selectAmount, setSelectedAmount } from '../../../reduxToolkit/slices/amount.slices';
import { selectIsBetCanceled, setIsBetCanceled } from '../../../reduxToolkit/slices/game.slices';
import { selectBetAmountOption } from '../../../reduxToolkit/slices/gameVersions.slices';
import { setMaxBetWarning } from '../../../reduxToolkit/slices/error.slices';

const BetAmountInput = () => {
  const languageData = useLanguageData();
  const dispatch = useDispatch();
  const selectedAmount = useSelector(selectAmount);
  const isBetCanceled = useSelector(selectIsBetCanceled);
  const betAmountOption = useSelector(selectBetAmountOption);

  const setMaxBet = () => {
    dispatch(setSelectedAmount(betAmountOption.maxBet));
    dispatch(setMaxBetWarning());
  };

  const handleSetAmount = ({ target: { value } }) => {
    isBetCanceled && dispatch(setIsBetCanceled(false));

    const targetStr = value.toString();
    const regex = /^[0-9]{0,9}?\d*\.?\d*$/;
    const valid = regex.test(targetStr);
    if (
      !valid ||
      targetStr[0] === '0' ||
      targetStr[0] === '.' ||
      (targetStr.split('.').length > 1 && targetStr.split('.')[1].length > 2)
    ) {
      return (+targetStr).toFixed(2);
    }
    targetStr <= betAmountOption.maxBet ? dispatch(setSelectedAmount(+targetStr)) : setMaxBet();
  };

  const deleteAmount = () => {
    isBetCanceled && dispatch(setIsBetCanceled(false));
    // let str = String(selectedAmount)
    // const targetStr = str.slice(0, str.length - 1);
    dispatch(setSelectedAmount(0));
  };

  // const handleResetAmount = () => {
  //   if (selectedAmount < betAmountOption.minBet) {
  //     setSelectedAmount(betAmountOption.minBet);
  //   }
  // };

  return (
    <div className={`input-controls__container ${selectedAmount ? 'active' : ''}`}>
      <label className="input-controls__input-label">
        <input
          type="text"
          className="input-controls__input"
          value={selectedAmount === 0 ? '' : selectedAmount}
          onChange={handleSetAmount}
          // onBlur={handleResetAmount}
        />
      </label>
      <button className="input-controls__clear-input-button" onClick={() => deleteAmount()}>
        {languageData.clear}
      </button>
    </div>
  );
};

export default BetAmountInput;
