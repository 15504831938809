import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { goToGameScene } from '../../reduxToolkit/slices/gameScene.slices';
import { selectActiveLanguage } from '../../reduxToolkit/slices/game.slices';

const playText = 'Play';

const PlayButton = () => {
  const dispatch = useDispatch();
  const language = useSelector(selectActiveLanguage);

  return (
    <div className="welcome__row welcome__row--play-button">
      <span
        className={`welcome__play-game ${language === 'sw' ? 'playButtonSw' : 'playButtonEn'}`}
        onClick={() => dispatch(goToGameScene())}>
        {playText}
      </span>
    </div>
  );
};

export default PlayButton;
