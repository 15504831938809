import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguageData } from '../../../Context/LanguageContext';
import { selectTab, setTab } from '../../../reduxToolkit/slices/tab.slices';

const Tab = ({ tabName }) => {
  const activeTab = useSelector(selectTab);
  const dispatch = useDispatch();
  const languageData = useLanguageData();
  const [containerName, className] = tabName;

  return (
    <li className="game-grid__info-tab">
      <span
        className={
          activeTab === containerName
            ? `game-grid__info-link game-grid__info-link--${className} active`
            : `game-grid__info-link game-grid__info-link--${className}`
        }
        onClick={() => dispatch(setTab(containerName))}>
        {languageData[containerName]}
      </span>
    </li>
  );
};

export default Tab;
