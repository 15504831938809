import play_game from './play-game.svg';
import time from './time.svg';
import statistics from './statistics.svg';
import random_original from './random-original.svg';
import arrow from './arrow.svg';
import basket from './basket.svg';
import minus from './minus.svg';
import plus from './plus.svg';
import id from './id.svg';
import info from './info.svg';
import sound from './sound.svg';
import sound_disable from './sound-disable.svg';
import phone from './phone.svg';
import keno_wrapper_last from './keno-wrapper-last.png';
import keno_text from './keno-text.png';
import step_1 from './1.png';
import step_2 from './2.png';
import step_3 from './3.png';
import step_4 from './4.png';
import step_5 from './5.png';
import step_6 from './6.png';
import step_7 from './7.png';
import infoButton from './infoButton.svg';
import numbersLogo from './Numbers-logo.png';
import drawResultarrow from './arrow-draw-result.svg';
import turboKenoLogo from './Skype_Picture_2022_03_10T11_55_37_188Z.jpeg';
import NrLogo from './NrLogo.svg';
import nrgaming from './nrgaming.svg';
import welcomeLogo from './welcomeLogo.png';
import homeIcon from './Home.svg';
import unavailabilityIcon from './Unavailability.svg';

const images = {
  step_1,
  step_2,
  step_3,
  step_7,
  step_4,
  step_5,
  step_6,
  keno_text,
  keno_wrapper_last,
  play_game,
  time,
  statistics,
  random_original,
  arrow,
  basket,
  minus,
  plus,
  id,
  info,
  sound_disable,
  sound,
  phone,
  infoButton,
  numbersLogo,
  drawResultarrow,
  turboKenoLogo,
  NrLogo,
  welcomeLogo,
  homeIcon,
  unavailabilityIcon,
  nrgaming,
};
export default images;
