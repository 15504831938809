import React from 'react';
import Loader from './Loader';
import { useSelector } from 'react-redux';
import { SCENE_NAMES } from '../../../constants/game';
import { useLanguageData } from '../../../Context/LanguageContext';
import { selectActiveScene } from '../../../reduxToolkit/slices/gameScene.slices';
import { selectCurrency } from '../../../reduxToolkit/slices/gameVersions.slices';
import { selectUserBalance, selectUserId } from '../../../reduxToolkit/slices/userData.slices';

const idText = 'ID';
const balanceText = 'Balance';

const { GAME_SCENE } = SCENE_NAMES;

const UserData = () => {
  const languageData = useLanguageData();
  const activeScene = useSelector(selectActiveScene);
  const currency = useSelector(selectCurrency);
  const userId = useSelector(selectUserId);
  const userBalance = useSelector(selectUserBalance);

  return (
    <div className="header__container left-width">
      <div className="user">
        {activeScene === GAME_SCENE && (
          <>
            <div className="user-amount">
              {languageData[balanceText]}: {userBalance} {currency}
            </div>
            <div className="user-id">
              {idText}: {userId}
            </div>
          </>
        )}
      </div>
      {activeScene === GAME_SCENE && <Loader />}
    </div>
  );
};

export default UserData;
