import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isHomeButtonAvtive: true,
  betAmountOption: {},
  currency: '',
  gameVersions: [],
};

export const gameVersionsSlice = createSlice({
  name: 'gameVersions',
  initialState,
  reducers: {
    setHomeButtonAvtive: (state, { payload }) => {
      state.isHomeButtonAvtive = payload;
    },
    setBetAmountOption: (state, { payload }) => {
      state.betAmountOption = payload;
    },
    setCurrency: (state, { payload }) => {
      state.currency = payload;
    },
    setGameVersions: (state, { payload }) => {
      state.gameVersions = payload;
    },
  },
});

// ACTIONS

export const { setHomeButtonAvtive, setBetAmountOption, setCurrency, setGameVersions } =
  gameVersionsSlice.actions;

// SELECTORS
export const selectIsHomeButtonAvtive = (state) => state.gameVersions.isHomeButtonAvtive;
export const selectBetAmountOption = (state) => state.gameVersions.betAmountOption;
export const selectCurrency = (state) => state.gameVersions.currency;
export const selectGameVersions = (state) => state.gameVersions.gameVersions;

export default gameVersionsSlice.reducer;
