import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLanguageData } from '../../Context/LanguageContext';
import { useSoundConstext } from '../../Context/SoundContext';
import { selectAmount, setSelectedAmount } from '../../reduxToolkit/slices/amount.slices';
import { resetBetButtons, selectBetBtns } from '../../reduxToolkit/slices/betBtn.slices';
import { countForGettingHistory } from '../../reduxToolkit/slices/historyCount.slices';
import { setError, setMinBetWarning } from '../../reduxToolkit/slices/error.slices';
import { selectFrontSecTillDraw } from '../../reduxToolkit/slices/frontSecTillDraw.slices';
import {
  selectIsBetCanceled,
  selectIsSoundActive,
  setIsBetCanceled,
} from '../../reduxToolkit/slices/game.slices';
import { selectIsRaffleActive } from '../../reduxToolkit/slices/raffle.slices';
import { selectBetAmountOption } from '../../reduxToolkit/slices/gameVersions.slices';
import { selectUserBalance } from '../../reduxToolkit/slices/userData.slices';
import { sendTicketThunk } from '../../reduxToolkit/thunks/sendTicket.thunk';
import { selectConfirmedTickets } from '../../reduxToolkit/slices/sendTicket.slices';

const ConfirmButton = ({ changeButtonActivity }) => {
  const dispatch = useDispatch();
  const languageData = useLanguageData();
  const useSoundData = useSoundConstext();
  const selectedAmount = useSelector(selectAmount);
  const selectedBetBtns = useSelector(selectBetBtns);
  const frontSecTillDraw = useSelector(selectFrontSecTillDraw);
  const isBetCanceled = useSelector(selectIsBetCanceled);
  const isRaffleActive = useSelector(selectIsRaffleActive);
  const betAmountOption = useSelector(selectBetAmountOption);
  const userBalance = useSelector(selectUserBalance);
  const isSoundActive = useSelector(selectIsSoundActive);
  const ticketReducer = useSelector(selectConfirmedTickets);

  const areButtonsChosen = selectedBetBtns.length > 0;

  useEffect(() => {
    if (isBetCanceled) {
      setIsBetCanceled(false);
    }
  }, [isRaffleActive]);

  async function handleConfirm() {
    if (areButtonsChosen && selectedAmount >= betAmountOption.minBet) {
      const ticketHeadNumber = ticketReducer.length + 1;
      changeButtonActivity(false);
      if (userBalance >= selectedAmount) {
        await dispatch(
          sendTicketThunk(makeTicket({ ticketHeadNumber, selectedBetBtns, selectedAmount })),
        );
        if (isRaffleActive) {
          dispatch(
            setError(
              'Participation in the current draw is closed. The placed bet will be accepted for the next draw.',
            ),
          );
        }
        //for geting userhistory
        dispatch(countForGettingHistory());
        changeButtonActivity(true);
        isSoundActive && useSoundData.bet();
      } else {
        dispatch(
          setError(
            'Your current balance is not enough to participate in the draw, please, refill it.',
          ),
        );
        changeButtonActivity(true);
        setIsBetCanceled(true);
      }
    } else if (selectedAmount < betAmountOption.minBet) {
      dispatch(setMinBetWarning());
      dispatch(setSelectedAmount(betAmountOption.minBet));
    } else if (!areButtonsChosen) {
      dispatch(
        setError('To participate in the draw, please, select your ticket numbers up to 10.'),
      );
    } else {
      console.log('error');
    }
  }

  const onResetClick = () => {
    isBetCanceled && setIsBetCanceled(false);
    dispatch(resetBetButtons());
  };

  // useEffect(() => {
  //   if (isBetCanceled) {
  //     setError(
  //       'Sorry, an unexpected error occurred while placing your bet. Please, try again later or contact support for assistance.',
  //     );
  //   }
  // }, [isBetCanceled]);

  return (
    <div className="clear-confirm">
      <button className="clear-confirm__clear" onClick={onResetClick} />
      {isRaffleActive && !isBetCanceled && (
        <div className="next-drow-bet_text">
          {languageData['Your bet will be accepted for the next draw']}
        </div>
      )}
      {isBetCanceled && (
        <div className="bet_is_not_accepted">
          <div>{languageData.betIsNotAccepted}</div>
        </div>
      )}
      <div className="confirm_button_wrapper">
        <button
          className={`clear-confirm__confirm-bet ${areButtonsChosen ? '' : 'disable'}`}
          onClick={frontSecTillDraw >= 0 ? () => handleConfirm() : null}>
          {languageData.bet.toUpperCase()}
        </button>
      </div>
    </div>
  );
};

function makeTicket({ ticketHeadNumber, selectedBetBtns, selectedAmount }) {
  const date = new Date().toISOString();
  const digits = selectedBetBtns;
  const betMoney = selectedAmount;

  return {
    id: ticketHeadNumber,
    date,
    digits,
    betMoney,
  };
}

export default ConfirmButton;
