import { createSlice } from '@reduxjs/toolkit';
// import { secondTillDrawExtraReducer } from '../thunks/secondTillDraw.thunk';

const initialState = {
  secTillDraw: null,
};

export const secondTillDrawSlice = createSlice({
  name: 'secTillDraw',
  initialState,
  // extraReducers: secondTillDrawExtraReducer,
  reducers: {
    setSecTillDraw: (state, { payload }) => {
      state.secTillDraw = payload;
    },
  },
});

// ACTIONS

export const { setSecTillDraw } = secondTillDrawSlice.actions;

// SELECTORS
export const selectSecTillDraw = (state) => state.secTillDraw.secTillDraw;

export default secondTillDrawSlice.reducer;
