import React from 'react';
import { useSelector } from 'react-redux';
import Ticket from './Ticket/Ticket';
import DynamicBetTicket from './DynamicBetTicket/DynamicBetTicket';
import { useLanguageData } from '../../../../Context/LanguageContext';
import { selectBetBtns } from '../../../../reduxToolkit/slices/betBtn.slices';
import {
  selectIsRaffleActive,
  selectPulledDigits,
  selectRaffleTickets,
} from '../../../../reduxToolkit/slices/raffle.slices';
import { selectBallFrequency } from '../../../../reduxToolkit/slices/gameData.slices';
import { selectConfirmedTickets } from '../../../../reduxToolkit/slices/sendTicket.slices';

const CurrentGame = () => {
  const languageData = useLanguageData();
  const selectedBetBtns = useSelector(selectBetBtns);
  const pulledDigits = useSelector(selectPulledDigits);
  const isRaffleActive = useSelector(selectIsRaffleActive);
  const { raffleTickets } = useSelector(selectRaffleTickets);
  const ballFrequency = useSelector(selectBallFrequency);
  const ticketReducer = useSelector(selectConfirmedTickets);

  const isTiketLineActive =
    isRaffleActive &&
    !!raffleTickets.length &&
    (!!selectedBetBtns.length || !!ticketReducer.length);

  return (
    <li className="game-grid__info-container game-grid__info-container--first active current-game current">
      <ul className="current-tickets">
        {isRaffleActive &&
          raffleTickets.map((ticket, index) => (
            <Ticket
              key={index}
              ticket={ticket}
              number={index + 1}
              ballFrequency={ballFrequency}
              pulledDigits={pulledDigits}
            />
          ))}
        {isTiketLineActive && (
          <div className="tiketsLineWrapper">
            <div className="ticketsLine" />
            <div className="ticketsLineText">{languageData['Next draw’s tickets']}</div>
          </div>
        )}
        {(selectedBetBtns.length && <DynamicBetTicket />) || ''}
        {ticketReducer.map((ticket, index) => (
          <Ticket key={index} ticket={ticket} number={index + 1} ballFrequency={ballFrequency} />
        ))}
      </ul>
    </li>
  );
};

export default CurrentGame;
