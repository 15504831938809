import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { generateRandomDigits } from '../../../helpers/game';
import { useSoundConstext } from '../../../Context/SoundContext';
import { addRandomBetBtns } from '../../../reduxToolkit/slices/betBtn.slices';
import {
  selectIsBetCanceled,
  selectIsSoundActive,
  setIsBetCanceled,
} from '../../../reduxToolkit/slices/game.slices';

const RandomDigit = ({ digit }) => {
  const useSoundData = useSoundConstext();
  const dispatch = useDispatch();
  const isBetCanceled = useSelector(selectIsBetCanceled);
  const isSoundActive = useSelector(selectIsSoundActive);

  const handleRandomChoice = () => {
    isSoundActive && useSoundData.select();
    isBetCanceled && dispatch(setIsBetCanceled(false));
    dispatch(addRandomBetBtns(generateRandomDigits(digit)));
  };

  return (
    <li className="random-button-container">
      <button className="random-button" value={digit} onClick={handleRandomChoice}>
        {digit}
      </button>
    </li>
  );
};

export default RandomDigit;
