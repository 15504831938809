import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  errorsPopup: false,
  isUnavailable: false,
  minBetWarning: false,
  maxBetWarning: false,
};

export const errorSlices = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setError: (state, { payload }) => {
      state.errorsPopup = payload;
    },
    closeErrorsPopup: (state) => {
      state.errorsPopup = false;
      state.minBetWarning = false;
      state.maxBetWarning = false;
    },
    setIsUnavailable: (state, { payload }) => {
      state.isUnavailable = payload;
    },
    setMinBetWarning: (state) => {
      state.minBetWarning = true;
      state.errorsPopup = true;
    },
    setMaxBetWarning: (state) => {
      state.maxBetWarning = true;
      state.errorsPopup = true;
    },
  },
});

// ACTIONS
export const { setError, closeErrorsPopup, setIsUnavailable, setMinBetWarning, setMaxBetWarning } =
  errorSlices.actions;

// SELECTORS
export const selectErrorsPopup = (state) => state.error.errorsPopup;
export const selectIsUnavailable = (state) => state.error.isUnavailable;
export const selectMinBetWarning = (state) => state.error.minBetWarning;
export const selectMaxBetWarning = (state) => state.error.maxBetWarning;

export default errorSlices.reducer;
