import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { resetIsFetching, setIsFetching } from '../slices/fetch.slices';
import { getToken, url } from '../../constants/api';
import { setCurrency } from '../slices/gameVersions.slices';
import { setUserBalance } from '../slices/userData.slices';
import { setError } from '../slices/error.slices';
import dataParser from '../../helpers/dataParser';
import { replaceTickets } from '../slices/sendTicket.slices';

export const getUserCurrentStateThunk = createAsyncThunk(
  'userCurrentState/getUserCurrentStateThunk',
  async (kenoType = 1, { dispatch }) => {
    try {
      dispatch(setIsFetching());

      const response = await axios.get(`${url}/get-user-current-state`, {
        params: {
          token: getToken(),
          duration: kenoType,
          page: 0,
        },
      });

      const gameData = response.data.data;
      const parsedData = dataParser(gameData);
      const {
        currency,
        userCurrentTickets,
        userData: { userBalance },
      } = parsedData;

      dispatch(setCurrency(currency));
      dispatch(replaceTickets(userCurrentTickets));
      dispatch(setUserBalance(userBalance));
    } catch (error) {
      console.error('keno/get-info', error);
      if (`${error}` === 'Error: Network Error') {
        dispatch(setError('Error. Low internet connection.'));
      } else {
        dispatch(setError('Something went wrong, please try again.'));
      }
      throw error;
    } finally {
      dispatch(resetIsFetching());
    }
  },
);
