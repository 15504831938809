import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  amountRanges: [],
  ballFrequency: {
    activeTab: null,
    balls: {},
  },
  userHistory: [],
  drawInfo: {},
};

export const gameDataSlice = createSlice({
  name: 'gameData',
  initialState,
  reducers: {
    setAmountRanges: (state, { payload }) => {
      state.amountRanges = payload;
    },
    setBallFrequency: (state, { payload }) => {
      state.ballFrequency = payload;
    },
    setFrequencyTab: (state, { payload }) => {
      state.ballFrequency = { ...state.ballFrequency, activeTab: payload };
    },
    setUserHistory: (state, { payload }) => {
      state.userHistory = payload;
    },
    setDrawInfo: (state, { payload }) => {
      state.drawInfo = payload;
    },
  },
});

// ACTIONS

export const { setAmountRanges, setBallFrequency, setFrequencyTab, setUserHistory, setDrawInfo } =
  gameDataSlice.actions;

// SELECTORS
export const selectAmountRanges = (state) => state.gameData.amountRanges;
export const selectBallFrequency = (state) => state.gameData.ballFrequency;
export const selectUserHistory = (state) => state.gameData.userHistory;
export const selectDrawInfo = (state) => state.gameData.drawInfo;

export default gameDataSlice.reducer;
