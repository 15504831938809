import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setSecTillDraw } from '../slices/secondTillDraw.slices';
import { setDrawInfo } from '../slices/gameData.slices';
import { setDrawHistory } from '../slices/drawResult.slices';
import { addRaffleInfo } from '../slices/raffle.slices';
import { setError } from '../slices/error.slices';
import { resetIsFetching, setIsFetching } from '../slices/fetch.slices';
import { getToken, url } from '../../constants/api';
import dataParser from '../../helpers/dataParser';
import { replaceTickets } from '../slices/sendTicket.slices';

export const getDrawResultsThunk = createAsyncThunk(
  'drawResults/getDrawResultsThunk',
  async (kenoType = 1, { dispatch }) => {
    try {
      dispatch(setIsFetching());

      const response = await axios.get(`${url}/get-draw-results`, {
        params: {
          token: getToken(),
          duration: kenoType,
          page: 0,
        },
      });

      const gameData = response.data.data;
      const parsedData = dataParser(gameData);
      const { drawHistoryTickets, userHistoryTickets, secTillDraw, drawInfo } = parsedData;
      dispatch(setSecTillDraw(secTillDraw));
      dispatch(setDrawHistory(drawHistoryTickets));
      dispatch(setDrawInfo(drawInfo));
      dispatch(replaceTickets([]));

      if (drawHistoryTickets[0].id === drawInfo.drawId - 1) {
        dispatch(
          addRaffleInfo({
            digits: drawHistoryTickets[0].digits,
            raffleTickets: userHistoryTickets[drawInfo.drawId - 1] || [],
          }),
        );
      } else {
        dispatch(setError('Error. Low internet connection.'));
        dispatch(
          addRaffleInfo({
            digits: [],
            raffleTickets: userHistoryTickets[drawInfo.drawId - 1] || [],
          }),
        );
      }
    } catch (error) {
      console.error('keno/get-info', error);
      if (`${error}` === 'Error: Network Error') {
        dispatch(setError('Error. Low internet connection.'));
      } else {
        dispatch(setError('Something went wrong, please try again.'));
      }
      throw error;
    } finally {
      dispatch(resetIsFetching());
    }
  },
);
