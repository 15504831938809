import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedAmount: '',
};

export const amountSlices = createSlice({
  name: 'amount',
  initialState,
  reducers: {
    setSelectedAmount: (state, { payload }) => {
      state.selectedAmount = payload;
    },
    plusAmount: (state, { payload }) => {
      state.selectedAmount =
        +state.selectedAmount + payload[0] <= payload[1]
          ? +state.selectedAmount + payload[0]
          : payload[1];
    },
  },
});

// ACTIONS
export const { setSelectedAmount, plusAmount } = amountSlices.actions;

// SELECTORS
export const selectAmount = (state) => state.amount.selectedAmount;

export default amountSlices.reducer;
