import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isRaffleActive: false,
  pulledDigits: [],
  raffleTickets: [],
};

export const raffleSlices = createSlice({
  name: 'raffle',
  initialState,
  reducers: {
    showRaffle: (state) => {
      state.isRaffleActive = true;
    },
    hideRaffle: (state) => {
      state.isRaffleActive = false;
    },
    setPulledDigit: (state, { payload }) => {
      state.pulledDigits = [...state.pulledDigits, payload].filter(
        (digit, index, array) => array.indexOf(digit) === index,
      );
    },
    resetPulledDigits: (state) => {
      state.pulledDigits = [];
    },
    addRaffleInfo: (state, { payload }) => {
      state.raffleTickets = payload;
    },
  },
});

// ACTIONS
export const { showRaffle, hideRaffle, setPulledDigit, resetPulledDigits, addRaffleInfo } =
  raffleSlices.actions;

// SELECTORS
export const selectIsRaffleActive = (state) => state.raffle.isRaffleActive;
export const selectPulledDigits = (state) => state.raffle.pulledDigits;
export const selectRaffleTickets = (state) => state.raffle.raffleTickets;

export default raffleSlices.reducer;
