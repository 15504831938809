import React from 'react';
import RaffleContainer from './RaffleContainer/RaffleContainer';
import GameInfo from './GameInfo/GameInfo';
import { useSelector } from 'react-redux';
import { selectIsRaffleActive } from '../../reduxToolkit/slices/raffle.slices';

const GameContainerLeft = () => {
  const isRaffleActive = useSelector(selectIsRaffleActive);

  return (
    <div className="game-grid__container game-grid__container--left left-width">
      {isRaffleActive ? <RaffleContainer /> : <p style={{ height: '79px' }}></p>}
      <GameInfo />
    </div>
  );
};

export default GameContainerLeft;
