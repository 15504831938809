import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Slides from './Slides';
import Table from './Table';
import Rules from './Rules';
import { useLanguageData } from '../../Context/LanguageContext';
import { changePopup, selectOpenPopup } from '../../reduxToolkit/slices/game.slices';

const Popup = () => {
  const dispatch = useDispatch();
  const openPopup = useSelector(selectOpenPopup);

  const languageData = useLanguageData();

  return (
    <div className={`${openPopup ? 'popup active' : 'popup'}`}>
      <div
        className="popup__container"
        style={{ borderTopRightRadius: '0', borderBottomRightRadius: '0' }}>
        <button onClick={() => dispatch(changePopup())} className="popup__close">
          Close popup
        </button>
        <Rules />
        <Table />
        <div className="popup__title">{languageData['howToPlay']}</div>
        <p className="popup__text">{languageData['kenoInfo']}</p>
        <Slides />
      </div>
      <div onClick={() => dispatch(changePopup())} className="popup__wrapper" />
    </div>
  );
};

export default Popup;
