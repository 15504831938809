import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, url } from '../../constants/api';
import { setError } from '../slices/error.slices';
import dataParser from '../../helpers/dataParser';
import { setSecTillDraw } from '../slices/secondTillDraw.slices';

export const getSecondTillDrawThunk = createAsyncThunk(
  'secondTillDraw/getSecondTillDrawThunk',
  async (kenoType = 1, { dispatch }) => {
    try {
      const response = await axios.get(`${url}/get-seconds-till-draw`, {
        params: {
          token: getToken(),
          duration: kenoType,
        },
      });
      const gameData = response.data.data;
      const parsedData = dataParser(gameData);
      const { secTillDraw } = parsedData;

      dispatch(setSecTillDraw(secTillDraw));

      if (gameData.error) {
        dispatch(setError(gameData.message));
        return;
      }

      return gameData.secondsTillDraw;
    } catch (error) {
      console.error(error);
      if (`${error}` === 'Error: Network Error') {
        dispatch(setError('Error. Low internet connection.'));
      } else {
        dispatch(setError('Something went wrong, please try again.'));
      }
      throw error;
    }
  },
);
