import React, { useState } from 'react';
import BetButtonsGrid from './BetButtonsGrid';
import RandomChoice from './RandomChoice/RandomChoice';
import BetAmountSelector from './BetAmountSelector/BetAmountSelector';
import ConfirmButton from './ConfirmButton';

const GameContainerRight = () => {
  const [isButtonActive, changeButtonActivity] = useState(true);

  return (
    <div className={`game-grid__container right-width ${!isButtonActive ? 'blur active' : ''}`}>
      <BetButtonsGrid />
      <RandomChoice />
      <BetAmountSelector />
      <ConfirmButton isButtonActive={isButtonActive} changeButtonActivity={changeButtonActivity} />
    </div>
  );
};

export default GameContainerRight;
