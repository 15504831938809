import React from 'react';
import TicketBody from './TicketBody';
import TicketHeader from './TicketHeader';
import RuleRowList from '../RuleRowList/RuleRowList';

const DynamicBetTicket = () => {
  return (
    <li className="current-ticket dont-choose">
      <TicketHeader />
      <TicketBody />
      <div className="current-ticket__grid-rule-possible-winning">
        <RuleRowList />
      </div>
    </li>
  );
};

export default DynamicBetTicket;
