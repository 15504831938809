import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  frontSecTillDraw: null,
};

export const frontSecTillDrawSlices = createSlice({
  name: 'frontSecTillDraw',
  initialState,
  reducers: {
    setFrontSecTillDraw: (state, { payload }) => {
      state.activeTab = payload;
    },
  },
});

// ACTIONS
export const { setFrontSecTillDraw } = frontSecTillDrawSlices.actions;

// SELECTORS
export const selectFrontSecTillDraw = (state) => state.frontSecTillDraw.frontSecTillDraw;

export default frontSecTillDrawSlices.reducer;
