import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import images from '../../assets/images';
import { goToMenuScene } from '../../reduxToolkit/slices/gameScene.slices';
import { hideRaffle, selectIsRaffleActive } from '../../reduxToolkit/slices/raffle.slices';

function HomeButton() {
  const dispatch = useDispatch();
  const isRaffleActive = useSelector(selectIsRaffleActive);

  const onHomeButtonClick = () => {
    if (isRaffleActive) {
      dispatch(hideRaffle());
    }
    dispatch(goToMenuScene());
  };

  return (
    <div onClick={() => onHomeButtonClick()} className="home-button">
      <img src={images.homeIcon} className="home-image" />
    </div>
  );
}

export default HomeButton;
