import React, { useState } from 'react';
import images from '../../../../../assets/images';
import { useLanguageData } from '../../../../../Context/LanguageContext';
import DrawHistoryCurrentTicketNumber from '../../DrawHistory/DrawHistoryCurrentTicketNumber';

function TicketDrawResult({ winCombination }) {
  const [isDrawResultActive, setIsDrawResultActive] = useState(false);
  const languageData = useLanguageData();

  return (
    <div>
      <div onClick={() => setIsDrawResultActive((prev) => !prev)} className="draw_result-continer">
        <div className="draw_result-text">{languageData.DrawResult}</div>
        <div className="draw_result-img_continer">
          <img
            className={`draw_result-img ${isDrawResultActive ? 'active' : ''}`}
            src={images.drawResultarrow}
          />
        </div>
      </div>
      <div className={`result-container ${isDrawResultActive ? 'active' : ''}`}>
        <div className="current-ticket__grid current-ticket__grid--body">
          <ul className="current-ticket__numbers">
            {Object.keys(winCombination).map((digit, index) => (
              <DrawHistoryCurrentTicketNumber key={index} digit={digit} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default TicketDrawResult;
