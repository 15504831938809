import React, { useEffect, useState } from 'react';
import images from '../../../assets/images';
import RaffleBoard from './RaffleBoard';
import { useSelector, useDispatch } from 'react-redux';
import { useLanguageData } from '../../../Context/LanguageContext';
import { useSoundConstext } from '../../../Context/SoundContext';
import { selectIsCongratsTime, setCongrats } from '../../../reduxToolkit/slices/congrats.slices';
import { countForGettingHistory } from '../../../reduxToolkit/slices/historyCount.slices';
import { selectKenoType } from '../../../reduxToolkit/slices/kenoType.slices';
import {
  hideRaffle,
  resetPulledDigits,
  selectRaffleTickets,
  setPulledDigit,
} from '../../../reduxToolkit/slices/raffle.slices';
import { getUserCurrentStateThunk } from '../../../reduxToolkit/thunks/userCurrentState.thunk';
import { selectIsSoundActive } from '../../../reduxToolkit/slices/game.slices';

const congratsWinsText = 'congratsWins';
const TIMEOUT_AFTER_DIGITS_PULL = 2000;
const TIMEOUT_AFTER_CONGRATS = 3000;
const DIGIT_PULL_TIMEOUT = 1500;
const PULL_DIGIT_COUNT = 20;
export const raffleDuration =
  (DIGIT_PULL_TIMEOUT * PULL_DIGIT_COUNT + TIMEOUT_AFTER_CONGRATS + TIMEOUT_AFTER_DIGITS_PULL) /
  1000;
let intervalIds = [];

const RaffleContainer = () => {
  const dispatch = useDispatch();
  const [bigDigit, setBigDigit] = useState('');
  const languageData = useLanguageData();
  const useSoundData = useSoundConstext();
  const isCongratsTime = useSelector(selectIsCongratsTime);
  const kenoType = useSelector(selectKenoType);
  const raffleTickets = useSelector(selectRaffleTickets);
  const isSoundActive = useSelector(selectIsSoundActive);

  const raffleBalls = raffleTickets?.digits;

  useEffect(() => {
    isSoundActive && useSoundData.ballDrop();
  }, [bigDigit]);

  useEffect(() => {
    (async function () {
      await pullBalls();
      await waiting(TIMEOUT_AFTER_DIGITS_PULL);
      dispatch(setCongrats(true));
      await waiting(TIMEOUT_AFTER_CONGRATS);
      dispatch(setCongrats(false));
      dispatch(hideRaffle());
      dispatch(resetPulledDigits());
      dispatch(getUserCurrentStateThunk(kenoType));
    })();

    return () => {
      intervalIds.forEach((id) => clearInterval(id));
      intervalIds = [];
    };
  }, [raffleBalls]);

  return (
    <div className="game-grid__raffle-states">
      <div className={`game-grid__raffle-state`}>
        <img
          className="game-grid__raffle-game-keno-text"
          src={images.keno_text}
          alt="images keno"
          width="319"
          height="161"
        />
      </div>

      <RaffleBoard bigDigit={bigDigit} isVisible={!isCongratsTime} />

      <div
        className={`game-grid__raffle-state  game-grid__raffle-state--congratulations ${
          isCongratsTime ? 'active' : ''
        } `}>
        <p>{languageData[congratsWinsText]}</p>
      </div>
    </div>
  );

  function pullBalls() {
    return new Promise((resolve) => {
      const tempDigits = [...raffleBalls];

      const id = setInterval(() => {
        const pulledDigit = tempDigits.shift();
        const isGameFinished = !tempDigits.length;

        setBigDigit((prevBigDigit) => {
          !!prevBigDigit && dispatch(setPulledDigit(prevBigDigit));
          return pulledDigit;
        });

        if (isGameFinished) {
          waiting(DIGIT_PULL_TIMEOUT).then(() => {
            dispatch(setPulledDigit(pulledDigit));
            setBigDigit('');
            //For Updating Userhistory Ticket Status
            dispatch(countForGettingHistory());
            resolve();
          });
          clearInterval(id);
        }
      }, DIGIT_PULL_TIMEOUT);
      intervalIds.push(id);
    });
  }
};

function waiting(ms) {
  return new Promise((resolve) => {
    const id = setTimeout(() => resolve(), ms);
    intervalIds.push(id);
  });
}

export default RaffleContainer;
