import React from 'react';
import images from '../../assets/images';
import { useLanguageData } from '../../Context/LanguageContext';

function Unavailability() {
  const languageData = useLanguageData();
  return (
    <div className="unavailabalContainer">
      <div className="unavailabilityImg">
        <img
          alt="unavailabilityIcon"
          src={images.unavailabilityIcon}
          className="unavailabilityIcon"
        />
      </div>
      <div className="notAvailableText">{languageData['notAvailable']}</div>
      <div className="tryAgainText">{languageData['tryAgain']}</div>
    </div>
  );
}

export default Unavailability;
