import { createSlice } from '@reduxjs/toolkit';
import { SCENE_NAMES } from '../../constants/game';

const { MENU_SCENE, LOADING_SCENE, GAME_SCENE } = SCENE_NAMES;

const initialState = {
  activeScene: LOADING_SCENE,
};

export const gameSceneSlices = createSlice({
  name: 'scene',
  initialState,
  reducers: {
    goToGameScene: (state) => {
      state.activeScene = GAME_SCENE;
    },
    goToMenuScene: (state) => {
      state.activeScene = MENU_SCENE;
    },
  },
});

// ACTIONS
export const { goToGameScene, goToMenuScene } = gameSceneSlices.actions;

// SELECTORS
export const selectActiveScene = (state) => state.scene.activeScene;

export default gameSceneSlices.reducer;
