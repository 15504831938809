import React from 'react';
import images from '../../assets/images';
import { useDispatch } from 'react-redux';
import { useLanguageData } from '../../Context/LanguageContext';
import { changePopup } from '../../reduxToolkit/slices/game.slices';

const HeaderInfoButton = () => {
  const dispatch = useDispatch();
  const languageData = useLanguageData();

  return (
    <li className="game-settings__item">
      <span onClick={() => dispatch(changePopup())} className="game-settings__button">
        <img
          src={images.info}
          width="11"
          height="15"
          alt="icon info"
          className="game-settings__image"
        />
        <span className="game-settings__text">{languageData.howToPlay}</span>
      </span>
    </li>
  );
};

export default HeaderInfoButton;
