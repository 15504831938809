import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getToken, url } from '../../constants/api';
import { setIsUnavailable } from '../slices/error.slices';
import { setGameType } from '../slices/game.slices';
import { setKenoType } from '../slices/kenoType.slices';
import { goToGameScene, goToMenuScene } from '../slices/gameScene.slices';
import {
  setBetAmountOption,
  setCurrency,
  setGameVersions,
  setHomeButtonAvtive,
} from '../slices/gameVersions.slices';
import dataParser from '../../helpers/dataParser';
import { GAME_TYPES } from '../../constants/names';

const { KENO, NUMBERS } = GAME_TYPES;

export const getGameVersionsThunk = createAsyncThunk(
  'gameVersions/getGameVersionsThunk',
  async (_, { dispatch }) => {
    try {
      const response = await axios.get(`${url}/get-game-versions`, {
        params: {
          token: getToken(),
          duration: 1,
        },
      });
      const gameData = response?.data;
      const { possibleGameVersions, kenoMode } = gameData.data;
      const parsedData = dataParser(gameData.data);
      const { betAmountOption, currency } = parsedData;

      dispatch(setGameType(kenoMode === 'numbers' ? NUMBERS : KENO));

      if (possibleGameVersions.length === 1) {
        const [kenoType] = possibleGameVersions;
        dispatch(setHomeButtonAvtive(false));
        dispatch(goToGameScene());
        dispatch(setKenoType(Number(kenoType)));
      } else {
        dispatch(setBetAmountOption(betAmountOption));
        dispatch(setCurrency(currency));
        dispatch(goToMenuScene());
      }
      dispatch(setGameVersions(possibleGameVersions));
    } catch (error) {
      dispatch(setIsUnavailable(true));
    }
  },
);
