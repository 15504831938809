import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DrawHistoryCurrentTicket from './DrawHistoryCurrentTicket';
import { selectIsRaffleActive } from '../../../../reduxToolkit/slices/raffle.slices';
import { selectDrawResult } from '../../../../reduxToolkit/slices/drawResult.slices';

function DrawHistory() {
  const isRaffleActive = useSelector(selectIsRaffleActive);
  const drawHistoryTickets = useSelector(selectDrawResult);
  const [newDrawHitroryArr, setNewDrawHitroryArr] = useState([]);

  useEffect(() => {
    const filteredDrawHistoryArr = isRaffleActive
      ? drawHistoryTickets.filter((item, index, arr) => item !== arr[0])
      : drawHistoryTickets;
    setNewDrawHitroryArr(filteredDrawHistoryArr);
  }, [isRaffleActive, drawHistoryTickets]);

  return (
    <li className="game-grid__info-container active">
      <ul className="current-tickets current-tickets--draw-history">
        {newDrawHitroryArr.map((ticket, index) => (
          <DrawHistoryCurrentTicket
            key={index}
            id={ticket.id}
            digits={ticket.digits}
            dt={ticket.dt}
          />
        ))}
      </ul>
    </li>
  );
}

export default DrawHistory;
