import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isCongratsTime: false,
};

export const congratsSlices = createSlice({
  name: 'congrats',
  initialState,
  reducers: {
    setCongrats: (state, { payload }) => {
      state.isCongratsTime = payload;
    },
  },
});

// ACTIONS
export const { setCongrats } = congratsSlices.actions;

// SELECTORS
export const selectIsCongratsTime = (state) => state.congrats.isCongratsTime;

export default congratsSlices.reducer;
