import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTimer } from '../../hooks/useTimer';
import { selectKenoType } from '../../reduxToolkit/slices/kenoType.slices';
import { setSecTillDraw } from '../../reduxToolkit/slices/secondTillDraw.slices';
import { getSecondTillDrawThunk } from '../../reduxToolkit/thunks/secondTillDraw.thunk';

function MenuTimer({ time }) {
  const dispatch = useDispatch();
  const kenoType = useSelector(selectKenoType);
  const [timerText, setTimerText] = useState('');
  const { timerCount, startTimer, stopTimer } = useTimer(time, 1000, true);

  useEffect(() => {
    startTimer();
    return stopTimer;
  }, [time]);

  useEffect(() => {
    setTimerText(createTimerText(timerCount));
    if (timerCount <= 0) {
      stopTimer();
      dispatch(setSecTillDraw(null));
      dispatch(getSecondTillDrawThunk(kenoType));
    }
  }, [timerCount]);

  return <div className="welcome__time">{!!timerCount ? timerText : ':'}</div>;
}

export function createTimerText(sec) {
  let timerInSeconds = sec;
  const minutes = '0' + Math.floor(timerInSeconds / 60);
  const seconds = '0' + Math.floor(timerInSeconds % 60);
  return `${minutes.slice(-2)} : ${seconds.slice(-2)}`;
}

export default MenuTimer;
