import React, { useEffect } from 'react';
import images from '../../assets/images';
import DurationButton from './DurationButton';
import PlayButton from './PlayButton';
import { useSelector } from 'react-redux';
import { GAME_TYPES } from '../../constants/names';
import MenuTimer from './MenuTimer';
import { selectGameType } from '../../reduxToolkit/slices/game.slices';
import { selectGameVersions } from '../../reduxToolkit/slices/gameVersions.slices';
import { selectSecTillDraw } from '../../reduxToolkit/slices/secondTillDraw.slices';
// import DemoButton from '../DemoButton/DemoButton';

const { NUMBERS } = GAME_TYPES;

const MenuScene = () => {
  const time = useSelector(selectSecTillDraw);
  const gameType = useSelector(selectGameType);
  const gameVersions = useSelector(selectGameVersions);

  return (
    <>
      <main className="game-grid game-grid--welcome">
        <div className="welcome">
          <div className="welcome__left">
            <img
              src={gameType === NUMBERS ? images.numbersLogo : images.turboKenoLogo}
              alt="wrapper keno"
              width="733"
              height="370"
              className="welcome__keno-img"
            />
          </div>
          <div className="welcome__right">
            {time ? <MenuTimer time={time} /> : <div className="welcome__time">00 : 00</div>}
            {gameVersions.length > 1 && (
              <div className="welcome__row welcome__row--inputs">
                {gameVersions.map((version) => {
                  return <DurationButton key={version} duration={+version} />;
                })}
              </div>
            )}
            <PlayButton />
            {/* <DemoButton /> */}
          </div>
        </div>
      </main>
    </>
  );
};

export default MenuScene;
