import React from 'react';
import { useSelector } from 'react-redux';
import AmountButton from './AmountButton';
import { selectAmountRanges } from '../../../reduxToolkit/slices/gameData.slices';

const BetAmountButtons = () => {
  const amountRanges = useSelector(selectAmountRanges);

  return (
    <ul className="input-controls__list">
      {amountRanges.map((amount) => {
        return <AmountButton key={amount} amount={amount} amountRanges={amountRanges} />;
      })}
    </ul>
  );
};

export default BetAmountButtons;
