import React from 'react';
import images from '../../assets/images';

function PowerdBy() {
  return (
    <div className="PowerdByContainer">
      <div className="PowerdByImgContainer">
        <img alt="Powerd By Logo" className="PowerdByImg" src={images.nrgaming} />
      </div>
    </div>
  );
}

export default PowerdBy;
