import React, { memo, useEffect } from 'react';
import RuleColumn from './RuleColumn';
import PossibleWin from './PossibleWin';
import { useLanguageData } from '../../../../../Context/LanguageContext';
import { addPayout } from '../../../../../reduxToolkit/slices/game.slices';

const RuleRow = ({ payouts, title }) => {
  const languageData = useLanguageData();

  const lastEl = payouts.length - 1;

  useEffect(() => {
    title === 'Payouts' && addPayout(payouts[lastEl]);
  }, [lastEl, payouts, title]);

  return (
    <div className="current-ticket__grid-rule-row">
      <div className="current-ticket__grid-rule-column">
        {title === 'Payouts' ? languageData.payouts : null}
        {title === 'Coincidences' ? languageData.coincidences : null}
      </div>
      {payouts.map(
        (payout, index) =>
          !!payout && (
            <RuleColumn
              key={index}
              payout={title === 'Payouts' ? `x${payout}` : !!payout && index}
            />
          ),
      )}
      {title === 'Payouts' && <PossibleWin />}
    </div>
  );
};

export default memo(RuleRow);
