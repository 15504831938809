import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeTab: 'Current Game',
};

export const tabSlices = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setTab: (state, { payload }) => {
      state.activeTab = payload;
    },
  },
});

// ACTIONS
export const { setTab } = tabSlices.actions;

// SELECTORS
export const selectTab = (state) => state.tabs.activeTab;

export default tabSlices.reducer;
