import React from 'react';
import InfoTabs from './InfoTabs';
import InfoContainer from './InfoContainer/InfoContainer';

const GameInfo = () => {
  return (
    <div className="game-grid__info">
      <InfoTabs />
      <InfoContainer />
    </div>
  );
};

export default GameInfo;
