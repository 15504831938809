import React from 'react';
import { forMap } from '../../../../../helpers/general';
import TicketDigit from './TicketDigit';
import { getTicketBallColor } from '../../../../../helpers/game';
import { useSelector } from 'react-redux';
import { selectBetBtns } from '../../../../../reduxToolkit/slices/betBtn.slices';
import { selectBallFrequency } from '../../../../../reduxToolkit/slices/gameData.slices';

const TicketRow = () => {
  const selectedBetBtns = useSelector(selectBetBtns);
  const ballFrequency = useSelector(selectBallFrequency);
  // const sortedBetBtns = selectedBetBtns.sort((a, b) => a - b);

  return (
    <div className="current-ticket__row">
      <ul className="current-ticket__numbers">
        {forMap(10, (i) => {
          const digit = selectedBetBtns[i];
          const color = getTicketBallColor(ballFrequency, digit);
          return <TicketDigit key={i} digit={digit} color={color} />;
        })}
      </ul>
    </div>
  );
};

export default TicketRow;
