import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { resetIsFetching, setIsFetching } from '../slices/fetch.slices';
import { getToken, url } from '../../constants/api';
import dataParser from '../../helpers/dataParser';
import { setBetAmountOption, setCurrency } from '../slices/gameVersions.slices';
import {
  setAmountRanges,
  setBallFrequency,
  setDrawInfo,
  setUserHistory,
} from '../slices/gameData.slices';
import { setSelectedAmount } from '../slices/amount.slices';
import { addRaffleInfo } from '../slices/raffle.slices';
import { setIsUnavailable } from '../slices/error.slices';
import { setSecTillDraw } from '../slices/secondTillDraw.slices';
import { setUserId } from '../slices/userData.slices';
import { setDrawHistory } from '../slices/drawResult.slices';
import { replaceTickets } from '../slices/sendTicket.slices';

export const getGameDataThunk = createAsyncThunk(
  'gameData/getGameDataThunk',
  async (kenoType = 1, { dispatch }) => {
    try {
      dispatch(setIsFetching());

      const response = await axios.get(`${url}/get-generic-info`, {
        params: {
          token: getToken(),
          duration: kenoType,
        },
      });

      const gameData = response.data.data;
      const parsedData = dataParser(gameData);
      const {
        currency,
        ballFrequency,
        drawHistoryTickets,
        userHistoryTickets,
        secTillDraw,
        drawInfo,
        betAmountOption,
        userCurrentTickets,
        userData,
        amountRanges,
      } = parsedData;
      dispatch(setCurrency(currency));
      dispatch(setAmountRanges(amountRanges));
      dispatch(setUserId(userData.userId));
      dispatch(setSecTillDraw(secTillDraw));
      dispatch(setBallFrequency(ballFrequency));
      dispatch(setDrawHistory(drawHistoryTickets));
      dispatch(setUserHistory(userHistoryTickets));
      dispatch(setDrawInfo(drawInfo));
      dispatch(setBetAmountOption(betAmountOption));
      dispatch(replaceTickets(userCurrentTickets));
      dispatch(setSelectedAmount(betAmountOption.minBet));
      dispatch(
        addRaffleInfo({
          digits: drawHistoryTickets[0].digits,
          raffleTickets: userHistoryTickets[drawInfo.drawId - 1] || [],
        }),
      );
    } catch (error) {
      dispatch(setIsUnavailable(true));
    } finally {
      dispatch(resetIsFetching());
    }
  },
);
