import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userBalance: null,
  userId: '',
};

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserBalance: (state, { payload }) => {
      state.userBalance = payload;
    },
    updateUserBalance: (state, { payload }) => {
      state.userBalance = payload;
    },
    setUserId: (state, { payload }) => {
      state.userId = payload;
    },
  },
});

// ACTIONS

export const { setUserBalance, setUserId, updateUserBalance } = userDataSlice.actions;

// SELECTORS
export const selectUserBalance = (state) => state.userData.userBalance;
export const selectUserId = (state) => state.userData.userId;

export default userDataSlice.reducer;
