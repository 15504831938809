import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSoundConstext } from '../../../Context/SoundContext';
import { plusAmount, selectAmount } from '../../../reduxToolkit/slices/amount.slices';
import {
  selectIsBetCanceled,
  selectIsSoundActive,
  setIsBetCanceled,
} from '../../../reduxToolkit/slices/game.slices';
import { selectBetAmountOption } from '../../../reduxToolkit/slices/gameVersions.slices';
import { setMaxBetWarning } from '../../../reduxToolkit/slices/error.slices';

const AmountButton = ({ amount, amountRanges }) => {
  const useSoundData = useSoundConstext();
  const dispatch = useDispatch();
  const isBetCanceled = useSelector(selectIsBetCanceled);
  const betAmountOption = useSelector(selectBetAmountOption);
  const isSoundActive = useSelector(selectIsSoundActive);
  const selectedAmount = useSelector(selectAmount);

  const clickHandler = () => {
    isSoundActive && useSoundData.addReduceAmount();
    isBetCanceled && dispatch(setIsBetCanceled(false));
    dispatch(plusAmount([amount, betAmountOption.maxBet]));
    if (+selectedAmount + amount > betAmountOption.maxBet) {
      dispatch(setMaxBetWarning());
    }
  };

  return (
    <li
      className={`input-controls__item ${
        amountRanges && amountRanges.length === 3 ? 'three-input-controls' : ''
      }`}>
      <button onClick={clickHandler} className="input-control__button">
        +{amount}
      </button>
    </li>
  );
};

export default AmountButton;
