import React from 'react';
import { useLanguageData } from '../../../../../Context/LanguageContext';
import { selectCurrency } from '../../../../../reduxToolkit/slices/gameVersions.slices';
import { useSelector } from 'react-redux';

const TicketOdds = ({ wonMoney, ticketStatus }) => {
  const languageData = useLanguageData();
  const currency = useSelector(selectCurrency);

  return (
    <div className="current-ticket__row">
      <div className="current-ticket__bet-title">
        {ticketStatus === 'won'
          ? languageData.win
          : ticketStatus === 'lost'
          ? languageData.loss
          : languageData.pending}
      </div>
      <div className="current-ticket__rate-odds">
        {ticketStatus === 'won' ? `${wonMoney} ${currency}` : '-'}
      </div>
    </div>
  );
};

export default TicketOdds;
