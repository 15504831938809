import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import getActiveLanguage from '../assets/languages';
import { GAME_TYPES } from '../constants/names';
import { selectGameType, setActiveLanguage } from '../reduxToolkit/slices/game.slices';

const LanguageContext = React.createContext({});

const LanguageProvider = ({ children }) => {
  const [languageData, setLanguageData] = useState(getActiveLanguage());
  const gameType = useSelector(selectGameType);
  const dispatch = useDispatch();

  useEffect(() => {
    if (gameType) {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const { lang = 'en' } = Object.fromEntries(urlSearchParams.entries());

      localStorage.setItem('fallbackLanguage', lang);

      const isNumbersType = gameType === GAME_TYPES.NUMBERS;
      setLanguageData(getActiveLanguage(lang, isNumbersType));
      dispatch(setActiveLanguage(lang));
    }
  }, [gameType]);

  return <LanguageContext.Provider value={languageData}>{children}</LanguageContext.Provider>;
};

export const useLanguageData = () => {
  return useContext(LanguageContext);
};

export default LanguageProvider;
